<template>
    <div class="post col-lg-8 border p-3 mb-3 bg-white overflow-auto text-left" v-if="this.post">
        
        <!-- user info -->
        <UserInfo :user="post.user" :subtext="formatDate(post.created_at)" />

        <!-- post content -->
        <div v-if="!edit" class="content mt-3 text-justify">
            <!-- img v-if="img" :src="img" alt="" @click="()=>$router.push({name: 'Image', params: {img:img}})" class="w-100" / -->
            <vue-simple-markdown :source="post.content" 
                :heading=false
                :highlight=false
                :image=false
                :table=false
                :class="post.content.length<50 ? 'text-center text-big' : 'text-left'"
            />
        </div>

        <!-- post edit -->
        <div v-else class="content mt-3 text-justify">

            <b-form-textarea 
                v-model="edited"
                rows="3"
                max-rows="6">
            </b-form-textarea>

            <div class="text-small text-muted mt-2 text-center">
                
                <!-- save changes -->
                <b-button variant="primary" class="mr-2" @click="$emit('change',{id: post.id, content: edited}); edit=false">
                    <b-icon icon="pencil-square" /> {{ $t('button.save') }}
                </b-button>

                <!-- cancel -->
                <b-button @click="edit=false">
                    <b-icon icon="x" /> {{ $t('button.cancel') }}
                </b-button>
            </div>

            <div class="text-small text-tiny text-center my-2">
                Markdown: `code`, *italic*, **bold**, * list, > quote
            </div>

        </div>

        <!-- post controls -->
        <div v-if="user" class="opts text-muted text-small text-right mt-2 pointer">
            
            <div v-if="post.created_at!=post.updated_at"
                class="float-left text-tiny">
                {{ $t('post.updated_at') }} {{ post.updated_at }}
            </div>

            <!-- edit -->
            <div v-if="post.user && post.user.id==user.id" 
                class="ml-4 d-inline-block"
                @click="editOn()">
                {{ $t('board.edit') }}
            </div>
            
            <!-- comment -->
            <!-- div class="ml-4 d-inline-block">{{ $t('board.comment') }}</div -->

            <!-- delete -->
            <div v-if="post.user && (post.user.id==user.id || (!global && user.project.role=='admin') || user.level>0)" 
                class="ml-4 d-inline-block"
                @click="$emit('del',post.id)">
                {{ $t('board.delete') }}
            </div>

        </div>

        <!-- video? -->
        <Player v-if="getEmbed(post.content)" :src="getEmbed(post.content)" />

        <!-- files -->
        <div v-if="post.files.length>0">
            <hr />
            <div class="row mb-2 pointer text-white text-small bg-light justify-content-center">


                <div v-for="(image, index) in post.files" 
                    :key="image.id" 
                    :class="[index==0 ? 'col-lg-12 col-md-12 col-sm-12 col-12' : 'col-lg-4 col-md-4 col-sm-6 col-6','image-box p-0']">

                    <div :class="[index==0 ? 'more-height' : '','img-wrapper d-flex align-items-center bg-info']" 
                        @click="!imgExt.includes(image.extension) ? download(image.name,image.title) : ''">
                        
                        <img v-if="imgExt.includes(image.extension)" 
                            :alt="image.title" 
                            :title="image.title" 
                            :src="filesURL+image.name"
                            @click="$emit('lightbox',{name:image.name,title:image.title})"    
                        />
                        
                        <div class="mx-auto text-center">
                            <b-icon icon="file-earmark" class="text-big" /> {{image.title}}
                        </div>
                    </div>
                </div>


            </div>
        </div>


    </div>
</template>

<style scoped>
.img-wrapper {
    height: 150px;
    overflow: hidden;
    border: 1px solid white;
    position: relative;
    background: black;
}
.img-wrapper img {
    width: 100%;
    height: auto;
}

.more-height {
    height: 250px !important;
}
</style>

<script>
import UserInfo from "@/views/PostComponents/UserInfo.vue";
import Player from "@/views/PostComponents/Player.vue";
import moment from 'moment';

export default {
    components: { UserInfo, Player },
    props: ['post','user','global'],

    data() {return{
        edit: false,
        edited: this.post.content
    }},

    computed: {
        contentParsed(){ if(this.post.content.length<50) return this.post.content; else return null; }
    },
    methods: {
        editOn() { this.edited = this.post.content; this.edit = true; },
        formatDate(date) { return moment(date).fromNow() + ' (' + moment(date).format('LLL') + ')'; },
    }

}
</script>
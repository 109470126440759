<template>
    <div class="row justify-content-center mx-0 px-0">

        <Header v-if="!global" :title="$t('board.board')" />

        <div v-else class="col-lg-8 mx-0 px-0">
            <img :src="require('@/assets/global-board.jpg')" class="img-fluid" alt="" />
        </div>

        <CreatePost v-if="auth"
            :user="auth"
            :noAttachments="global"
            @save="createPost($event)" />

        <Post 
            v-for="(post, index) in posts" 
            :key="post.id" 
            :post="post" 
            :user="auth" 
            :global="global"
            @del="delItem('posts',$event,index)" 
            @change="changeItem('posts',$event,index)"
            @lightbox="showImage($event)"
            />

        <Request :global="global" :contents="posts" :text="$t('board.none')" />

        <div v-if="allposts && posts.length>0" class="col-12 text-center text-muted">{{ $t('board.allposts') }}</div>
        
        <PlusButton @click="$bvModal.show('new-post')" />

    </div>
</template>

<script>
import Vue from 'vue';
import Post from "@/views/PostComponents/Post.vue";
import CreatePost from "@/views/PostComponents/CreatePost.vue";

export default {
    name: "Board",
    components: { Post, CreatePost },
    props: { 
        global: { type: Boolean, default: false }
    },
    /**
     * DATA
     */
    data() { return{
        posts: [],
        start: 0,
        limit: 25,
        allposts: false,
        // plus: [
        //     { name: "board.create", modal: "new-post"},
        //     { name: this.global ? "board.project" : "board.global", route: this.global ? "Board" : "GlobalBoard"}
        // ],
    }},

    /**
     * CREATED
     */
    async created() { 
        await this.loadPosts(); 
        this.postsRead();
        this.scroll();

        // event listener
        let channel = this.global ? "0" : this.auth.project.id;
        window.Echo.private('board.'+ channel)
            .listen('PostEvent', (incoming) => { 
                if(incoming.action=='add') this.pushPost(incoming.post);
                if(incoming.action=='del') this.removePost(incoming.post);
                if(incoming.action=='update') this.updatePost(incoming.post);
        });

    },

    /** 
     * METHODS
     */
    methods: {

        /**
         * PUSH POST
         */
        async pushPost(post) {
            if(this.auth.id==post.user_id) return false;
            let newpost = await this.axon('get','posts/'+post.id);
            this.posts.unshift(newpost);
            this.postsRead();
            this.$store.commit("alert",this.$t('board.wrote',{name: newpost.user.name}));
        },

        /** 
         * REMOVE POST 
         */
        removePost(post) {
            let ix = this.index('posts',post.id);
            if(ix>-1) this.posts.splice(ix,1);
        },

        /**
         * UPDATE POST
         */
        async updatePost(post) {
            let ix = this.index('posts',post.id);
            let newpost = await this.axon('get','posts/'+post.id);
            if(ix>-1) Vue.set(this.posts,ix,newpost);
        },

        /**
         * SCROLL BOTTOM
         */
        scrollBottom() { this.loadPosts(); },
        
        /**
         * LOAD POSTS
         */
        async loadPosts() {
            // if no project or all posts do nothing
            if(this.allposts) return false;
            let project_id = this.global ? 0 : this.auth.project.id;
            // if not do the twist
            let newposts = await this.axon('get','posts?p='+project_id+'&s='+this.start+'&l='+this.limit);
            if(newposts && newposts.length>0) { 
                this.start = this.start+this.limit;
                this.posts = this.posts.concat(newposts); 
            }
            else this.allposts = true;
            return true;
        },
        
        /**
         * CREATE POST
         */
        async createPost(event) {
            let formData = new FormData();
            formData.append('content',event.content ?? '');
            formData.append('project_id', this.global ? 0 : this.auth.project.id)
            event.files.forEach((file,index)=>{ formData.append("files["+index+"]", file) });
            await this.createItem('post',formData, true); 
            this.goTop();
            this.postsRead();

        },

        /** 
         * SET READ
         */
        postsRead() { 
            if(this.posts[0] && !this.global) {
                this.axon("put","users/"+this.auth.id,{lastpost: [this.auth.project.id, this.posts[0].id]}); 
                this.notify = ['Board',0];
            }
        },


    },
}
</script>
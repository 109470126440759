<template>
<div>
    <!-- modal -->
    <b-modal 
        id="new-post"  
        :title="$t('board.create')" 
        headerBgVariant="primary"
        headerTextVariant="white"
        class="pl-0"
        @show="showPreview()" 
        @hide="content='';files=[]"
        centered
        busy>

        <!-- user info -->
        <UserInfo :subtext="$t('board.creatingin')" :user="user" />

        <!-- image list -->
        <div v-if="files.length>0" class="row mb-2 pointer text-white text-small">
            <div v-for="(image, key) in files" :key="image.name" class="image-box col-lg-4 col-md-4 col-sm-6 col-6 p-0">
                <div class="img-wrapper d-flex align-items-center bg-info" ref="wrapper">
                    <img ref="image" :alt="image.name" />
                    <div ref="doc" class="mx-auto text-center">
                        <b-icon icon="file-earmark" class="text-big" /> {{image.name}}
                    </div>
                    <b-button @click="files.splice(key,1)" variant="dark" class="text-light del-button"><b-icon icon="x" /></b-button>
                </div>
            </div>

            <!-- add image button -->
            <div class="image-box col-lg-4 col-md-4 col-sm-6 col-6 p-0">
                <div class="img-wrapper bg-info d-flex align-items-center" @click="$refs.upload.$el.click()">
                    <div class="plus-icon mx-auto m-0 p-0"><b-icon icon="file-earmark-plus" /></div>
                </div>
            </div>
        </div>

        <!-- error box -->
        <!-- div v-html="info" class="text-danger text-small text-center mb-2" / -->

        <!-- add content -->
        <b-form-textarea
            id="content"
            v-model="content"
            :placeholder="$t('board.placeholder')"
            rows="2"
            class="mb-2"
        />
        
        <!-- upload image hidden action -->
        <b-form-file v-if="!noAttachments"
            ref="upload"
            @change="addImage"
            multiple
            plain
            class="d-none"
        />

        <!-- action buttons -->
        <template v-slot:modal-footer>
            <!-- publish -->
            <b-button variant="primary" size="sm" class="mr-2" @click="$emit('save',{content:content,files:files})">
                {{ $t('board.publish') }}
            </b-button>
            <!-- attachements -->
            <b-button v-if="!noAttachments" variant="info" size="sm" class="mr-2" @click="$refs.upload.$el.click()">
                <b-icon icon="paperclip" />
            </b-button>
        </template>

    </b-modal>
</div>
</template>

<style scoped>
.img-wrapper {
    height: 150px;
    overflow: hidden;
    border: 1px solid white;
    position: relative;
    background: black;
}
.img-wrapper img {
    width: 100%;
    height: auto;
}
.del-button {
    position: absolute;
    z-index: 1000;
    right: 5px;
    top: 5px;
    border-radius: 15px;
    padding: 0 2px;
    margin: 0;
    opacity: 0.9;
}
.plus-icon {
    font-size: 5em;
    color: white;
    font-weight: bold;
}

</style>

<script>
import UserInfo from "@/views/PostComponents/UserInfo.vue";
export default {
    components: { UserInfo },
    props: {
        noAttachments: { type: Boolean, default: false },
        user: { type: Object, default: null }
    },
    data() { return{
        content: null,
        info: null,
        files: [],
        ext: {
            images: [
            'image/jpeg',
            'image/png',
            'image/gif', 
            ],
            docs: [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/epub+zip',
            'application/vnd.oasis.opendocument.spreadsheet',
            'application/vnd.oasis.opendocument.text',
            'application/vnd.oasis.opendocument.presentation',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/rtf',
            'text/plain',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'text/xml',
            'application/xml',
            'application/zip',
            'application/x-7z-compressed',
            'text/csv',
            ],
        },
        limit: {
            single: 5,
            all: 10
        }
    }},
    computed: {
        extAll() {
            return this.ext.images.concat(this.ext.docs);
        }
    },
    methods: {
        addImage(e=null) {
            if(this.noAttachments) return false;
            this.info = null;
            //let files = e.target.files;
            if(e) {
                for(let i = 0; i < e.target.files.length; i++) { 
                    
                    // check size
                    if(this.tooBig(e.target.files[i].size)) {
                        this.$store.commit("alert", this.tooBig(e.target.files[i].size));
                        break;
                    }
                    
                    // check extension
                    if(this.badExt(e.target.files[i].type)) {
                        this.$store.commit("alert", this.$t('file.badext'));
                        continue; }
                    
                    // push file to array
                    this.files.push(e.target.files[i]); 
                }
            }
            this.showPreview();
              
        },
        showPreview() {
            if(this.noAttachments) return false;
            if (this.files.length>0) {
                for(let x in this.files) {
                    let reader = new FileReader();
                    reader.onload = () => { 
                        if(this.fileType(this.files[x].type) == "image")
                            this.$refs.image[x].src = reader.result; 
                        else this.$refs.image[x].classList.add("d-none"); }
                    reader.readAsDataURL(this.files[x]); }}},

        fileType(file) {
            for(let x in this.ext.images) { if(file == this.ext.images[x]) return "image"; }
            return "doc"; },

        badExt(file) {
            for(let x in this.extAll) { if(file == this.extAll[x]) return false; }
            return true; },

        tooBig(file) {
            file = file/1000000;
            let all = 0;
            if(file>this.limit.single) return this.$t('file.filetoobig',{size: this.limit.single });
            for(let x in this.files) { all = all + (this.files[x].size/1000000); }
            if((all+file)>this.limit.all) return this.$t('file.attachtoobig',{size: (all+file).toFixed(0) });
            return false; },

        delImage(x) {
            this.$delete(this.files,x); },

    }
}
</script>
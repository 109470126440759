<template>
    <div class="mt-2">
    <iframe 
        width="100%" 
        height="300" 
        :src="src" 
        frameborder="0" 
        allow="fullscreen; accelerometer; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen>
    </iframe>
    </div>
</template>

<script>
export default {
    props: ['src']
}
</script>